import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import formatDateIndonesia from "../utils/index";
import LoadingComponent from "../components/Loading";
import { baseURL } from "../utils/config";

export default function TagPage() {
  const { id } = useParams();

  const GET_LAST_POST = gql`
    query getAllPosts($offset: Int, $tag: ID!, $size: Int, $keyword: String) {
      posts(
        where: {
          tagIn: [$tag]
          search: $keyword
          offsetPagination: { offset: $offset, size: $size }
        }
      ) {
        nodes {
          databaseId
          excerpt
          title
          id
          date
          content
          slug
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              id
            }
          }
          tags {
            nodes {
              name
              id
            }
          }
        }
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
      }
      tag(id: $tag) {
        id
        name
      }
    }
  `;

  const search = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  let { loading, error, data, refetch } = useQuery(GET_LAST_POST, {
    variables: { keyword, offset, size, tag: id },
    fetchPolicy: "network-only",
  });

  const [dataPost, setDataPost] = useState([]);
  const [stateTag, setStateTag] = useState("");

  useEffect(() => {
    if (!loading) {
      setDataPost(data.posts.nodes);
      setStateTag(data.tag.name);
      console.log(error);
    }
  }, [loading]);

  const onSearch = async (e) => {
    if (search.current.value === "") {
      setKeyword("");
    } else {
      setOffset(0);
      setCurrentPage(1);
      setKeyword(search.current.value);
    }
  };

  const onPrev = (e) => {
    setCurrentPage((currentPage) => currentPage - 1);
    setOffset((offset) => offset - size);
  };

  const onNext = (e) => {
    setCurrentPage((currentPage) => currentPage + 1);
    setOffset((offset) => offset + size);
  };

  return (
    <>
      <Navbar />

      <div className="mx-[10%] md:mb-[20px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5">
          <div className="order-2 md:order-1 col-span-2 md:p-[30px] md:flex md:flex-row">
            Halaman Tag : {!loading && stateTag}
          </div>
          <div className="order-1 md:order-2 col-span-1 md:mt-[30px]">
            <div className="flex flex-row">
              <input
                className="m-[10px] w-[100%]"
                type="text"
                placeholder="Tulis Disini"
                name=""
                id=""
                ref={search}
              />
              <button
                onClick={(e) => onSearch(e)}
                className="m-[10px] bg-[#D9D9D9] p-[10px] rounded-full"
              >
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-[10%] md:mb-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5 gap-[100px]">
          {!loading ? (
            dataPost.length > 0 ? (
              dataPost.map((item, index) => {
                return (
                  <div key={index} className="col-span-1">
                    <div className="w-[100%] h-[220px] bg-[#FFDE59]">
                      {item.featuredImage && (
                        <img
                          className="text-center mx-auto my-auto"
                          style={{ maxHeight: "220px" }}
                          alt={item.featuredImage.node.altText}
                          src={item.featuredImage.node.sourceUrl}
                        />
                      )}
                    </div>
                    <div className="text-[#515151] text-[10px] my-[12px]">
                      {formatDateIndonesia(item.date)}
                    </div>
                    <div className="text-[#006225] text-[20px] font-bold mb-[15px]">
                      {item.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.excerpt,
                      }}
                      className="text-[#292929] text-[14px] mb-[15px]"
                    ></div>
                    <div className="text-[#515151] text-[10px] mb-[10px]">
                      {item.categories.nodes.map((item) => item.name + ", ")}
                    </div>
                    <a href={baseURL + "/detail/" + item.id}>
                      <button className="font-bold text-[12px] p-[8px] bg-[#006225] text-white rounded-full">
                        Baca Selengkapnya &gt;
                      </button>
                    </a>
                  </div>
                );
              })
            ) : (
              <div className="col-span-3 text-center">Data tidak ditemukan</div>
            )
          ) : (
            <div className="col-span-3">
              <LoadingComponent />
            </div>
          )}
        </div>
      </div>

      {!loading && (
        <div className="mx-[10%] flex justify-center space-x-4 mt-[50px]">
          {data.posts.pageInfo.offsetPagination.hasPrevious && (
            <button
              onClick={(e) => onPrev(e)}
              className="px-3 py-1 bg-[#D9D9D9] rounded-md"
            >
              {" "}
              {"<"}{" "}
            </button>
          )}
          {/* <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          1
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          2
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          3
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          ...
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          7
        </a> */}
          {data.posts.pageInfo.offsetPagination.hasMore && (
            <button
              onClick={(e) => onNext(e)}
              className="px-3 py-1 bg-[#D9D9D9] rounded-md"
            >
              {" "}
              {">"}{" "}
            </button>
          )}
        </div>
      )}

      <Footer />
    </>
  );
}
