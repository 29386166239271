import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import LoadingComponent from "../components/Loading";
import formatDateIndonesia from "../utils/index";
import useIsVisible from "../utils/checkVisible";
import Fade from "react-reveal/Fade";

export default function DetailHorizontalPage() {
  const { id } = useParams();

  const GET_DETAIL_POST = gql`
    query getAllPosts($id: ID!) {
      post(id: $id) {
        databaseId
        excerpt
        title
        id
        date
        content
        slug
        categories {
          edges {
            node {
              id
              name
            }
          }
        }
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_DETAIL_POST, {
    variables: { id: id },
  });

  const [refBerita, isVisibleBerita] = useIsVisible();

  return (
    <>
      <Navbar />

      <div ref={refBerita}>
        <Fade left when={isVisibleBerita}>
          <div class="mt-[50px] mx-[11%]">
            {!loading ? (
              <>
                {/* <span class="text-[40px] md:text-[60px]">Detail</span> */}
                <div class="container flex flex-col md:flex-row items-center bg-white rounded-lg md:max-w-full dark:bg-white">
                  <div class="container flex flex-col w-[100%] h-[100%] md:h-[400px] ">
                    {data.post.featuredImage && (
                      <img
                        className="text-center mx-auto my-auto"
                        style={{ maxHeight: "389px" }}
                        alt={data.post.featuredImage.node.altText}
                        src={data.post.featuredImage.node.sourceUrl}
                      />
                    )}
                  </div>
                  <div class="flex flex-col justify-between p-4 leading-normal">
                    <h5 class="mb-2 px-[6%] text-2xl font-bold tracking-tight text-gray-900 dark:text-[#006225]">
                      {data.post.title}
                    </h5>
                    <p
                      dangerouslySetInnerHTML={{ __html: data.post.content }}
                      class="mb-3 px-[6%] font-normal text-gray-700 dark:text-[#292929]"
                    ></p>
                    <p class="mb-3 px-[6%] font-normal text-gray-700 dark:text-[#515151]">
                      Kategori : {data.post.categories.edges[0].node.name}
                    </p>
                    <p class="mb-3 px-[6%] font-normal text-gray-700 dark:text-[#515151]">
                      {formatDateIndonesia(data.post.date)}
                    </p>
                  </div>
                </div>{" "}
              </>
            ) : (
              <LoadingComponent />
            )}
          </div>
        </Fade>
      </div>

      <Footer />
    </>
  );
}
