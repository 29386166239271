import React, { useEffect } from "react";
import { baseURL } from "../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCheckAuthUser,
  asyncUnsetAuthUser,
} from "../states/authUser/action";
import { useNavigate, Link } from "react-router-dom";

export default function Navbar() {
  const { authUser } = useSelector((states) => states);
  const dispatch = useDispatch(); // @TODO: get dispatch function from store
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(asyncCheckAuthUser());
  }, []);

  const onLogout = () => {
    dispatch(asyncUnsetAuthUser());
    navigate("/login");
  };

  return (
    <>
      <nav className="z-20 bg-[#006225] border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-[#006225]">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <a href="/" className="flex items-center">
            <img
              src={baseURL + "/assets/logo-white.png"}
              className="h-6 mr-3 sm:h-9"
              alt=" Logo"
            />
            <span className="self-center text-[12px] font-semibold whitespace-nowrap text-white dark:text-white">
              IKATAN ALUMNI TEKNIK LINGKUNGAN <br />
              INSTITUT TEKNOLOGI BANDUNG
            </span>
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-400 rounded-lg md:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600
           dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 border bg-[#006225] border-gray-700 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-[#006225] dark:bg-[#006225] md:dark:bg-[#006225] dark:border-gray-700">
              <li>
                <a
                  href="/"
                  className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  aria-current="page"
                >
                  Beranda
                </a>
              </li>
              <li>
                <button
                  id="dropdownNavbarButton"
                  data-dropdown-toggle="dropdownNavbar"
                  data-dropdown-trigger="hover"
                  class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:p-0 md:w-auto rounded  hover:text-black"
                >
                  Tentang Kami{" "}
                  <svg
                    class="w-4 h-4 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  id="dropdownNavbar"
                  class="z-50 relative hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownNavbarButton"
                  >
                    <li>
                      <a
                        href="/about"
                        class="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        Profil
                      </a>
                    </li>
                    <li>
                      <a
                        href="/lingkar"
                        class="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        Lingkar
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              {/* <li>
                <a
                  href="/agenda"
                  className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Agenda
                </a>
              </li> */}

              <li>
                <a
                  href="/project"
                  className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Project
                </a>
              </li>

              <li>
                <button
                  id="dropdownNavbarButtonEnviro"
                  data-dropdown-toggle="dropdownNavbarEnviro"
                  data-dropdown-trigger="hover"
                  class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:p-0 md:w-auto rounded  hover:text-black"
                >
                  Enviro Talk{" "}
                  <svg
                    class="w-4 h-4 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  id="dropdownNavbarEnviro"
                  class="z-50 relative hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownNavbarButton"
                  >
                    <li>
                      <a
                        href="/enviro-webinar"
                        class="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        Enviro Webinar
                      </a>
                    </li>
                    <li>
                      <a
                        href="/podcast"
                        class="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        Podcast
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </li>

              {authUser && (
                <li>
                  <a
                    href="/alumni"
                    className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Direktorat Alumni
                  </a>
                </li>
              )}

              <li>
                <button
                  id="dropdownNavbarButtonPublication"
                  data-dropdown-toggle="dropdownNavbarPublication"
                  data-dropdown-trigger="hover"
                  class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:p-0 md:w-auto rounded  hover:text-black"
                >
                  Publikasi{" "}
                  <svg
                    class="w-4 h-4 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  id="dropdownNavbarPublication"
                  class="z-50 relative hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownNavbarButton"
                  >
                    <li>
                      <a
                        href="/newsletter"
                        class="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        Newsletter
                      </a>
                    </li>
                    <li>
                      <a
                        href="/public-policy"
                        class="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        Public Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/publikasi"
                        className="block px-4 py-2 text-black hover:text-white hover:bg-gray-700"
                      >
                        General Publication
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a
                  href="/blog"
                  className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Blog
                </a>
              </li>

              {!authUser ? (
                <li>
                  <a
                    href="/login"
                    className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Login
                  </a>
                </li>
              ) : (
                <li>
                  <button
                    onClick={onLogout}
                    className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0 dark:text-white md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
