/**
 * @TODO: Define all the actions (creator) for the authUser state
 */
import Swal from 'sweetalert2';
import api from '../../utils/api';

const ActionType = {
  SET_AUTH_USER: 'SET_AUTH_USER',
  UNSET_AUTH_USER: 'UNSET_AUTH_USER',
};

function setAuthUserActionCreator(authUser) {
  return {
    type: ActionType.SET_AUTH_USER,
    payload: {
      authUser,
    },
  };
}

function unsetAuthUserActionCreator() {
  return {
    type: ActionType.UNSET_AUTH_USER,
    payload: {
      authUser: null,
    },
  };
}

function asyncCheckAuthUser() {
  return async (dispatch) => {
    try {
      const token = api.getAccessToken();
      // const response = await api.validate({ username, password });

      // const authUser = await api.getOwnProfile();

      dispatch(setAuthUserActionCreator(token));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      })
    }
  };
}

function asyncSetAuthUser({ username, password }) {
  return async (dispatch) => {
    try {
      const token = await api.login({ username, password });
      console.log(token);
      api.putAccessToken(token);
      // const authUser = await api.getOwnProfile();

      dispatch(setAuthUserActionCreator(token));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      })
    }
  };
}

function asyncUnsetAuthUser() {
  return (dispatch) => {
    dispatch(unsetAuthUserActionCreator());
    api.putAccessToken('');
  };
}

export {
  ActionType,
  setAuthUserActionCreator,
  unsetAuthUserActionCreator,
  asyncSetAuthUser,
  asyncCheckAuthUser,
  asyncUnsetAuthUser,
};
