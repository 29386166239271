import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function NotFoundPage() {
  return (
    <>
      <Navbar />

      <div className="container mx-auto my-auto">
        <h1 className="text-[50px] mt-[100px] text-center">Maaf halaman tidak ditemukan</h1>
      </div>

      <Footer />
    </>
  );
}
