import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, Redirect } from "react-router-dom";
import { asyncSetAuthUser } from "../states/authUser/action";
import Swal from "sweetalert2";
import { baseURL } from "../utils/config";

export default function LoginPage() {
  const username = useRef(null);
  const password = useRef(null);
  const navigate = useNavigate();
  const { authUser } = useSelector((states) => states);
  const dispatch = useDispatch(); // @TODO: get dispatch function from store

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
      if(token) {
        navigate('/');
      }
  },[]);

  const onLogin = ({ username, password }) => {
    dispatch(asyncSetAuthUser({ username, password })).then(() => {
      setTimeout(()=>{
        window.location.reload(false);
    }, 500);
    });
    // const token = localStorage.getItem("accessToken");
    // if (!token) {
    //   // navigate("/");
    //   return <Redirect to='/'  />
    //   // window.location.replace(baseURL);
    // }
    // if(authUser){
    //   navigate("/");
    // }
  };

  return (
    <>
      <Navbar />

      <div className="container mx-auto my-auto">
        <div class="flex flex-col justify-center sm:px-6 lg:px-8">
          <div class="sm:mx-auto mt-[70px] sm:w-full sm:max-w-md">
            <h2 class="mt-2 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div class="mt-3 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onLogin({
                    username: username.current.value,
                    password: password.current.value,
                  });
                }}
                class="space-y-6"
                action="#"
                method="POST"
              >
                <div>
                  <label
                    for="username"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Username
                  </label>
                  <div class="mt-1">
                    <input
                      id="username"
                      name="username"
                      type="text"
                      autocomplete="username"
                      required
                      ref={username}
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="password"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div class="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="current-password"
                      required
                      ref={password}
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg
                        class="h-5 w-5 text-green-500 group-hover:text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 8.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 11-1.414 1.414L10 7.414V15a1 1 0 11-2 0V7.414L6.707 8.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
