import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PostPage from "./pages/PostPageBack";
import "./styles.css";

import { ApolloProvider } from "@apollo/client/react";
import client from "./lib/apollo";
import AboutPage from "./pages/AboutPage";
import AgendaPage from "./pages/AgendaPage";
import BlogPage from "./pages/BlogPage";
import EnviroWebinarPage from "./pages/EnviroWebinarPage";
import PodcastPage from "./pages/PodcastPage";
import AlumniPage from "./pages/AlumniPage";
import PublikasiPage from "./pages/PublikasiPage";
import LingkarPage from "./pages/LingkarPage";
import NotFoundPage from "./pages/NotFoundPage";
import DetailPage from "./pages/DetailPage";
import DetailHorizontalPage from "./pages/DetailHorizontalPage";
import NewsPage from "./pages/NewsPage";
import LoginPage from "./pages/LoginPage";
import ProjectPage from "./pages/ProjectPage";
import NewsletterPage from "./pages/NewsletterPage";
import PublicPolicyPage from "./pages/PublicPolicyPage";
import ProjectDetailPage from "./pages/ProjectDetailPage";
import LogoutPage from "./pages/LogoutPage";
import TagPage from "./pages/TagPage";

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/login" element={<LoginPage/>} />
        {/* <Route path="/logout" element={<LogoutPage/>} /> */}
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/agenda" element={<AgendaPage/>} />
        <Route path="/blog" element={<BlogPage/>} />
        <Route path="/enviro-webinar" element={<EnviroWebinarPage/>} />
        <Route path="/podcast" element={<PodcastPage/>} />
        <Route path="/project" element={<ProjectPage/>} />
        <Route path="/detailproject/:id" element={<ProjectDetailPage/>} />
        <Route path="/berita" element={<NewsPage/>} />
        <Route path="/newsletter" element={<NewsletterPage/>} />
        <Route path="/public-policy" element={<PublicPolicyPage/>} />
        <Route path="/lingkar" element={<LingkarPage/>} />
        <Route path="/alumni" element={<AlumniPage/>} />
        <Route path="/publikasi" element={<PublikasiPage/>} />
        <Route path="/detail/:id" element={<DetailPage/>} />
        <Route path="/detailhr/:id" element={<DetailHorizontalPage/>} />
        <Route path="/tag/:id" element={<TagPage/>} />
        <Route path='*' element={<NotFoundPage/>} />

      </Routes>
    </ApolloProvider>
  );
}
