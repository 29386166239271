import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import useIsVisible from "../utils/checkVisible";
import Fade from "react-reveal/Fade";

export default function AboutPage() {
  const photoHolder = useRef(null);

  function hoverImage(e) {
    photoHolder.current.src = e.currentTarget.src;
  }

  function slideImage(i) {
    const imageList = [
      // 'assets/ketua1.png',
      "assets/ketua2.png",
      "assets/ketua3.png",
      "assets/ketua4.png",
    ];
    photoHolder.current.src = imageList[i % imageList.length];
  }

  useEffect(() => {
    let i = 0;
    setInterval(function () {
      slideImage(i);
      i++;
    }, 4000);
  }, []);

  const [refComponent, isVisibleComponent] = useIsVisible();

  return (
    <>
      <Navbar />

      <div ref={refComponent}>
        <Fade left when={isVisibleComponent}>
          <div className="mx-[2%] md:mb-[100px] md:mt-[100px] mt-[50px]">
            <div className="grid grid-cols-1 md:grid-cols-3 m-5">
              <div className="col-span-1">
                <div className="bg-white text-center justify-center flex items-center">
                  <div className="absolute w-[360px] h-[360px] rounded-full bg-[#DB901F] mx-auto"></div>
                  <img
                    ref={photoHolder}
                    id="photoHolder"
                    src="assets/ketua2.png"
                    alt="Image description"
                    className="z-50 w-[412px]"
                  />
                </div>
              </div>
              <div className="col-span-2 mt-[50px]">
                <div className="bg-white px-[50px] font-[16px]">
                  <p>
                    Periode kali ini, <strong>IATL tampil beda</strong>. <br />
                    <br />
                    Melalui kampanye dengan gembira, kongres yang guyub,
                    memutuskan koalisi, didapatlah
                    <strong>3 Ketua IATL Periode 2021-2025</strong>. Teh Chitra
                    Retna, kang Gun Gun Saptari, kang Andrian Tejakusuma.
                    <strong>Teh Chitra</strong> membawa spirit bottom-up, skill
                    fasilitasi, dari pengalaman panjang bekerja di Development
                    Sector (Article 33 Indonesia, RWI, Chemonics USAID).
                    <strong>Kang Gun Gun</strong> membawa spirit edukasi dan
                    “ngasuh,” pengalaman yang dibawa sejak aktif di dunia
                    training/motivasi dan program-program lingkungan, termasuk
                    karir di PD Kebersihan Bandung.{" "}
                    <strong>Kang Andrian</strong>, membawa spirit kalem tapi
                    menghanyutkan dari perjalanan panjang berkarir di dunia
                    konsultan dan organisasi karate. <br />
                    <br />
                    Menyatukan ketiga jalan pedang berbeda, dalam satu
                    kepengurusan, ternyata sangat menguatkan. Ditambah
                    <strong>5 Wakil Ketua </strong> (kang Lastyo, kang Fajar,
                    Kang Fariz, teh Annie, kang Ricky) dan pengurus yang setiap
                    saat boleh bertambah dari 23 Lingkar Kegiatan yang terus
                    bertambang seiring aspirasi anggota, IATL mencoba membangun
                    platform dengan dua kata kunci:{" "}
                    <strong>Guyub dan Speak Up</strong>.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-[2%] md:mb-[50px]">
            <div className="grid grid-cols-1 md:grid-cols-2 m-5">
              <div className="col-span-1 p-[30px]">
                <h2 className="text-[36px] font-bold text-[#006225] mb-[30px]">
                  Tujuan IATL
                </h2>
                <ol className="list-decimal list-outside">
                  <li>
                    Turut serta dan berperan aktif dalam Pembangunan Nasional
                    yang berwawasan lingkungan.
                  </li>
                  <li>
                    Menjadi sarana komunikasi di antara alumni Teknik
                    Penyehatan, Teknik Lingkungan, dan Rekayasa Infrastruktur
                    Lingkungan ITB
                  </li>
                  <li>
                    Membangun, menjalin, dan menyinergikan potensi alumni Teknik
                    Penyehatan, Teknik Lingkungan, dan Rekayasa Infrastruktur
                    Lingkungan ITB
                  </li>
                  <li>
                    Berperan aktif dalam meningkatkan kualitas pendidikan
                    program studi Teknik Lingkungan ITB dan Program Studi
                    Rekayasa Infrastruktur Lingkungan ITB
                  </li>
                  <li>
                    Mendukung pengembangan ilmu pengetahuan dan teknologi di
                    bidang lingkungan serta meningkatkan hasil guna dan daya
                    guna kemampuannya dengan cara mengamalkan untuk kepentingan
                    dan kesejahteraan bangsa Indonesia khususnya dan umat
                    manusia pada umumnya.
                  </li>
                </ol>
              </div>
              <div className="col-span-1 p-[30px]">
                <h2 className="text-[36px] font-bold text-[#006225] mb-[30px]">
                  Sejarah IATL
                </h2>
                <p className="">
                  Bahwasannya para Alumni Teknik Lingkungan lnstitut Teknologi
                  Bandung menyadari tanggung jawabnya sebagai mahluk sosial dan
                  pemeran dalam proses pembangunan nasional, bertekad bulat
                  untuk menyumbangkan dharma baktinya dengan cara mengamalkan
                  pengetahuan dan pengalamannya dalam wujud pemikiran,
                  saran-saran, langkah-langkah dan kegiatan-kegiatan dalam suatu
                  wadah kebersamaan dan kekeluargaan yang melembaga dengan
                  mendirikan IATL ini pada 15 Desember 2007
                </p>
              </div>
            </div>
          </div>

          <div className="mx-[2%] md:mb-[50px]">
            <div className="grid grid-cols-1 md:grid-cols-1 m-5">
              <div className="col-span-1 p-[30px]">
                <h2 className="text-[36px] font-bold text-[#006225] mb-[30px]">
                  Organogram IATL
                </h2>
                <img
                  src="assets/organogram1.png"
                  className="w-[723px] mx-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <Footer />
    </>
  );
}
