import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { CSSTransition } from "react-transition-group";
import { gql, useQuery } from "@apollo/client";
import LoadingComponent from "../components/Loading";
import formatDateIndonesia from "../utils/index";
import { baseURL } from "../utils/config";

export default function NewsletterPage() {
  const GET_LAST_POST = gql`
    query getAllPosts(
      $offset: Int
      $category: ID
      $size: Int
      $keyword: String
    ) {
      posts(
        where: {
          categoryIn: ["dGVybTo5OQ==", $category]
          search: $keyword
          offsetPagination: { offset: $offset, size: $size }
        }
      ) {
        nodes {
          databaseId
          excerpt
          title
          id
          date
          content
          slug
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              id
            }
          }
        }
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
      }
    }
  `;

  const categoryList = [
    {
      id: "dGVybToxMDU=",
      name: "Newsletter",
    },
    {
      id: "dGVybToxMDg=",
      name: "Public Policy",
    },
    // {
    //   id: "dGVybToxMDc=",
    //   name: "General Publication",
    // },
  ];

  const search = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSingle, setSelectedSingle] = useState("");
  let { loading, error, data, refetch } = useQuery(GET_LAST_POST, {
    variables: { keyword, offset, size, category: selectedSingle },
    fetchPolicy: "network-only",
  });
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [dataAgenda, setDataAgenda] = useState([]);

  useEffect(() => {
    if (!loading) {
      setDataAgenda(data.posts.nodes);
    }
  }, [loading]);

  const [showCategory, setShowCategory] = useState(false);

  const handleButtonClick = () => {
    setShowCategory(!showCategory);
  };

  const onSearch = async (e) => {
    if (search.current.value === "") {
      setKeyword("");
    } else {
      setOffset(0);
      setCurrentPage(1);
      setKeyword(search.current.value);
    }
  };

  const onPrev = (e) => {
    setCurrentPage((currentPage) => currentPage - 1);
    setOffset((offset) => offset - size);
  };

  const onNext = (e) => {
    setCurrentPage((currentPage) => currentPage + 1);
    setOffset((offset) => offset + size);
  };

  const toggleCategory = (e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("data-id");
    setSelectedSingle(id);
    // const checkCategory = selectedCategory.includes(id);
    // let tempSelected = selectedCategory;
    // if(checkCategory){
    //   tempSelected = tempSelected.filter(category => category !== id);
    //   setSelectedCategory(tempSelected);
    // } else {
    //   tempSelected.push(id);
    //   setSelectedCategory(tempSelected);
    // }
    // console.log(selectedCategory);
    // console.log(checkCategory);
  };

  return (
    <>
      <Navbar />

      <div className="mx-[10%] md:mb-[20px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5">
          <div className="order-2 md:order-1 col-span-2 md:p-[30px] md:flex md:flex-row">
            <div
              onClick={handleButtonClick}
              className="bg-[#D9D9D9] w-[121px] p-[10px] rounded-full text-center m-[10px]"
            >
              Kategori:
            </div>
            <CSSTransition
              in={showCategory}
              timeout={500}
              classNames="slide-down"
              unmountOnExit
            >
              <>
                {categoryList.map((category) => {
                  return (
                    <button
                      onClick={(e) => toggleCategory(e)}
                      data-id={category.id}
                      key={category.id}
                      className={
                        "m-[10px] text-[12px] text-[#292929] text-center p-[10px] " +
                        (selectedCategory.includes(category.id)
                          ? "bg-green-700"
                          : "")
                      }
                    >
                      {category.name}
                    </button>
                  );
                })}
              </>
            </CSSTransition>
          </div>
          <div className="order-1 md:order-2 col-span-1 md:mt-[30px]">
            <div className="flex flex-row">
              <input
                className="m-[10px] w-[100%]"
                type="text"
                placeholder="Tulis Disini"
                name=""
                id=""
                ref={search}
              />
              <button
                onClick={(e) => onSearch(e)}
                className="m-[10px] bg-[#D9D9D9] p-[10px] rounded-full"
              >
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-[10%] md:mb-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5 gap-[100px]">
          {!loading ? (
            dataAgenda.length > 0 ? (
              dataAgenda.map((item, index) => {
                return (
                  <div key={index} className="col-span-1">
                    <div className="w-[100%] h-[220px]">
                      {item.featuredImage && (
                        <img
                          className="text-center mx-auto my-auto"
                          style={{ maxHeight: "220px" }}
                          alt={item.featuredImage.node.altText}
                          src={item.featuredImage.node.sourceUrl}
                        />
                      )}
                    </div>
                    <div className="text-[#515151] text-[10px] my-[12px]">
                      {formatDateIndonesia(item.date)}
                    </div>
                    <div className="text-[#006225] text-[20px] font-bold mb-[15px]">
                      {item.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.excerpt,
                      }}
                      className="text-[#292929] text-[14px] mb-[15px]"
                    ></div>
                    <div className="text-[#515151] text-[10px] mb-[10px]">
                      {item.categories.nodes.map((item) => item.name + ", ")}
                    </div>
                    <a href={baseURL + "/detail/" + item.id}>
                      <button className="font-bold text-[12px] p-[8px] bg-[#006225] text-white rounded-full">
                        Baca Selengkapnya &gt;
                      </button>
                    </a>
                  </div>
                );
              })
            ) : (
              <div className="col-span-3 text-center">Data tidak ditemukan</div>
            )
          ) : (
            <div className="col-span-3">
              <LoadingComponent />
            </div>
          )}
        </div>
      </div>

      {!loading && (
        <div className="mx-[10%] flex justify-center space-x-4 mt-[50px]">
          {data.posts.pageInfo.offsetPagination.hasPrevious && (
            <button
              onClick={(e) => onPrev(e)}
              className="px-3 py-1 bg-[#D9D9D9] rounded-md"
            >
              {" "}
              {"<"}{" "}
            </button>
          )}
          {/* <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          1
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          2
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          3
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          ...
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          7
        </a> */}
          {data.posts.pageInfo.offsetPagination.hasMore && (
            <button
              onClick={(e) => onNext(e)}
              className="px-3 py-1 bg-[#D9D9D9] rounded-md"
            >
              {" "}
              {">"}{" "}
            </button>
          )}
        </div>
      )}

      <Footer />
    </>
  );
}
