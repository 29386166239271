import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { gql, useQuery } from "@apollo/client";
import { CSSTransition } from "react-transition-group";
import LoadingComponent from "../components/Loading";
import formatDateIndonesia from "../utils/index";
import { baseURL } from "../utils/config";

export default function PublikasiPage() {
  const GET_LAST_POST = gql`
    query getAllPosts(
      $offset: Int
      $size: Int
      $keyword: String
    ) {
      mediaItems(where: { mimeType: APPLICATION_PDF, search: $keyword, offsetPagination: {offset: $offset, size: $size} }) {
        nodes {
          id
          mediaItemUrl
          title
          description
          date
          mediaItemUrl
        }
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
      }
    }
  `;

  const search = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSingle, setSelectedSingle] = useState("");
  let { loading, error, data, refetch } = useQuery(GET_LAST_POST, {
    variables: { keyword, offset, size },
    fetchPolicy: "network-only",
  });

  const [dataAgenda, setDataAgenda] = useState([]);

  useEffect(() => {
    if (!loading) {
      setDataAgenda(data.mediaItems.nodes);
    }
  }, [loading]);

  const [showCategory, setShowCategory] = useState(false);

  const handleButtonClick = () => {
    setShowCategory(!showCategory);
  };

  const onSearch = async (e) => {
    if (search.current.value === "") {
      setKeyword("");
    } else {
      setOffset(0);
      setCurrentPage(1);
      setKeyword(search.current.value);
    }
  };

  const onPrev = (e) => {
    setCurrentPage((currentPage) => currentPage - 1);
    setOffset((offset) => offset - size);
  };

  const onNext = (e) => {
    setCurrentPage((currentPage) => currentPage + 1);
    setOffset((offset) => offset + size);
  };

  const toggleCategory = (e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("data-id");
    // setSelectedSingle(id);
    setKeyword(id);
  };

  return (
    <>
      <Navbar />

      <div className="mx-[10%] md:mb-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5">
          <div className="order-2 md:order-1 col-span-2 md:p-[30px] md:flex md:flex-row">
            <div
              onClick={handleButtonClick}
              className="bg-[#D9D9D9] w-[121px] p-[10px] rounded-full text-center m-[10px]"
            >
              Kategori:
            </div>
            <CSSTransition
              in={showCategory}
              timeout={500}
              classNames="slide-down"
              unmountOnExit
            >
              <>
                <button
                  onClick={(e) => toggleCategory(e)}
                  data-id=""
                  className="m-[10px] text-[#292929] text-center p-[10px]"
                >
                  Semua
                </button>
                <button
                  onClick={(e) => toggleCategory(e)}
                  data-id="CER-IA"
                  className="m-[10px] text-[#292929] text-center p-[10px]"
                >
                  CER-IA
                </button>
                <button
                  onClick={(e) => toggleCategory(e)}
                  data-id="Generic Publication"
                  className="m-[10px] text-[#292929] text-center p-[10px]"
                >
                  Generic Publication
                </button>
              </>
            </CSSTransition>
          </div>
          <div className="order-1 md:order-2 col-span-1 md:p-[30px]">
            <div className="flex flex-row">
              <input
                className="m-[10px] w-[100%]"
                type="text"
                placeholder="Tulis Disini"
                name=""
                id=""
                ref={search}
              />
               <button
                onClick={(e) => onSearch(e)}
                className="m-[10px] bg-[#D9D9D9] p-[10px] rounded-full"
              >
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-[10%] md:mb-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5 gap-y-12 gap-x-[200px]">
          {!loading ? (
            dataAgenda.length > 0 ? (
              dataAgenda.map((item, index) => {
                return (
                  <div className="col-span-1">
                    <div className="w-[230px] h-[355px] bg-[#FFDE59]">
                      {/* {item.featuredImage && (
                        <img
                          className="text-center mx-auto my-auto"
                          style={{ maxHeight: "355px" }}
                          alt={item.featuredImage.node.altText}
                          src={item.featuredImage.node.sourceUrl}
                        />
                      )} */}
                      <embed
                        src={item.mediaItemUrl}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="text-[#515151] text-[10px] my-[12px]">
                      {formatDateIndonesia(item.date)}
                    </div>
                    <div className="text-[#006225] text-[20px] font-bold mb-[15px]">
                      <a href={item.mediaItemUrl} target="_blank" rel="noreferrer"> {item.title} </a>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                      className="text-[#292929] text-[14px] mb-[15px]"
                    ></div>
                    {/* <div className="text-[#515151] text-[10px] mb-[10px]">
                      {item.categories.nodes.map((item) => item.name + ", ")}
                    </div>
                    <a href={baseURL + "/detail/" + item.id}>
                      <button className="font-bold text-[12px] p-[8px] bg-[#006225] text-white rounded-full">
                        Baca Selengkapnya &gt;
                      </button>
                    </a> */}
                  </div>
                );
              })
            ) : (
              <div className="col-span-3 text-center">Data tidak ditemukan</div>
            )
          ) : (
            <div className="col-span-3">
              <LoadingComponent />
            </div>
          )}
        </div>
      </div>

      {!loading && (
        <div className="mx-[10%] flex justify-center space-x-4 mt-[50px]">
          {data.mediaItems.pageInfo.offsetPagination.hasPrevious && (
            <button
              onClick={(e) => onPrev(e)}
              className="px-3 py-1 bg-[#D9D9D9] rounded-md"
            >
              {" "}
              {"<"}{" "}
            </button>
          )}
          {/* <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          1
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          2
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          3
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          ...
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          7
        </a> */}
          {data.mediaItems.pageInfo.offsetPagination.hasMore && (
            <button
              onClick={(e) => onNext(e)}
              className="px-3 py-1 bg-[#D9D9D9] rounded-md"
            >
              {" "}
              {">"}{" "}
            </button>
          )}
        </div>
      )}

      <Footer />
    </>
  );
}
