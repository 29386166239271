import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { gql, useQuery } from "@apollo/client";
import formatDateIndonesia from "../utils/index";
import { useNavigate, Link } from "react-router-dom";
import { ReactPhotoCollage } from "react-photo-collage";
import { CountUp } from "countup.js";
import { baseURL } from "../utils/config";
import Fade from "react-reveal/Fade";
import axios from "axios";
import useIsVisible from "../utils/checkVisible";

export default function HomePage() {
  const GET_LAST_SLIDER = gql`
    query getAllPosts {
      posts(where: { categoryName: "Slider" }, last: 9) {
        nodes {
          databaseId
          excerpt
          title
          id
          date
          content
          slug
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  `;

  const {
    loading: loadingSlider,
    error: errorSlider,
    data: dataSlider,
  } = useQuery(GET_LAST_SLIDER);

  const GET_LAST_POST = gql`
    query getAllPosts {
      posts(first: 4, where: { categoryIn: ["dGVybTo3Ng=="] }) {
        nodes {
          databaseId
          excerpt
          title
          id
          date
          content
          slug
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
        }
      }
      mediaItems(
        where: {
          mimeType: IMAGE_JPEG
          offsetPagination: { offset: 0, size: 30 }
        }
      ) {
        nodes {
          mediaItemId
          mediaItemUrl
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LAST_POST);

  const [isLoadingAngka, setIsloadingAngka] = useState(true);
  const [angka, setAngka] = useState([]);

  useEffect(() => {
    const options = {
      url: "https://api.fureweb.com/spreadsheets/1YdRZg_NFLbPsRixWCYUsEeUD2F29mIhA0kX0r7AYDis",
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    };

    axios(options)
      .then((response) => {
        const responseData = response.data.data;
        setAngka(responseData[0]);
        new CountUp("jumlahAlumni", responseData[0].JumlahAlumni, {
          enableScrollSpy: true,
        });
        new CountUp("database", responseData[0].Database, {
          enableScrollSpy: true,
        });
        new CountUp("kolabolator", responseData[0].Kolabolator, {
          enableScrollSpy: true,
        });
        new CountUp("reach", responseData[0].Reach, { enableScrollSpy: true });
        new CountUp("program", responseData[0].Program, {
          enableScrollSpy: true,
        });
        new CountUp("partisipanKegiatan", responseData[0].PartisipanKegiatan, {
          enableScrollSpy: true,
        });
        setIsloadingAngka(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const settingCollage = {
    width: "500px",
    height: ["100px", "100px", "100px"],
    layout: [5, 5, 5],
    photos: !loading
      ? data.mediaItems.nodes.map((media) => {
          return {
            source: media.mediaItemUrl,
          };
        })
      : [
          {
            source:
              "https://images.unsplash.com/photo-1682961941145-e73336a53bc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2874&q=80",
          },
        ],
    showNumOfRemainingPhotos: true,
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const videoId = getId("https://youtu.be/QBx1Aj2AV-o");
  const iframeMarkup =
    '<iframe width="500" height="300" src="//www.youtube.com/embed/' +
    videoId +
    '" frameborder="0" allowfullscreen></iframe>';

  const [refBerita, isVisibleBerita] = useIsVisible();

  return (
    <>
      <Navbar />

      <div
        // data-anijs="if: scroll, on: window, do: swing animated, to: footer"
        id="default-carousel"
        className="relative lg:h-[600px]"
        data-carousel="static"
      >
        <div className="relative h-56 overflow-hidden lg:h-[600px] md:h-96">
          {!loadingSlider ? (
            dataSlider.posts.nodes
              .filter((item) => item.featuredImage !== null)
              .slice(0, 3)
              .map((sliderItem) => {
                return (
                  <div
                    className="hidden duration-700 ease-in-out"
                    data-carousel-item
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                    style={{display: 'block', 
                    margin: '0 auto'  
                  }}
                      src={sliderItem.featuredImage.node.sourceUrl}
                      className="absolute block 
                       mx-auto
                      h-full lg:h-[600px]"
                      alt={sliderItem.featuredImage.node.name}
                    />
                    <div className="opacity-70 bg-black absolute block w-full h-full lg:h-[600px]"></div>
                    <span className="absolute lg:text-[34px] text-center font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/4 left-1/2 sm:text-3xl dark:text-white-800">
                      {sliderItem.title}
                    </span>
                    <span className="absolute lg:text-[16px] font-semibold text-white -translate-x-1/2 -translate-y-1/2 lg:top-[40%] top-[50%] left-1/2 sm:text-3xl dark:text-[#FFDE59]">
                      {formatDateIndonesia(sliderItem.date)}
                    </span>
                    <span className="absolute mt-[20px] lg:text-[16px] font-semibold text-white text-center -translate-x-1/2 -translate-y-1/2 top-[60%] left-1/2 sm:text-3xl dark:text-white lg:visible invisible">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sliderItem.excerpt,
                        }}
                      ></span>
                      <Link to={"/detail/" + sliderItem.id}>
                        <button className="font-bold text-[12px] p-[8px] bg-[#006225] text-white rounded-full">
                          Baca Selengkapnya {">"}
                        </button>
                      </Link>
                    </span>
                  </div>
                );
              })
          ) : (
            <>
              <div
                className="hidden duration-700 ease-in-out"
                data-carousel-item
              >
                <img
                  src="assets/hero.png"
                  className="absolute block w-full h-full lg:h-[600px]"
                  alt="..."
                />
                <div className="opacity-70 bg-black absolute block w-full h-full lg:h-[600px]"></div>
                <span className="absolute lg:text-[34px] text-center font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/4 left-1/2 sm:text-3xl dark:text-white-800">
                  Loading...
                </span>
                <span className="absolute lg:text-[16px] font-semibold text-white -translate-x-1/2 -translate-y-1/2 lg:top-[40%] top-[50%] left-1/2 sm:text-3xl dark:text-[#FFDE59]">
                  Loading...
                </span>
                <span className="absolute mt-[20px] lg:text-[16px] font-semibold text-white text-center -translate-x-1/2 -translate-y-1/2 top-[60%] left-1/2 sm:text-3xl dark:text-white lg:visible invisible">
                  Loading...
                </span>
              </div>
              <div
                className="hidden duration-700 ease-in-out"
                data-carousel-item
              >
                <img
                  src="assets/hero.png"
                  className="absolute block w-full h-full lg:h-[600px]"
                  alt="..."
                />
                <div className="opacity-70 bg-black absolute block w-full h-full lg:h-[600px]"></div>

                <span className="absolute lg:text-[34px] text-center font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/4 left-1/2 sm:text-3xl dark:text-white-800">
                  Loading...
                </span>
                <span className="absolute lg:text-[16px] font-semibold text-white -translate-x-1/2 -translate-y-1/2 lg:top-[40%] top-[50%] left-1/2 sm:text-3xl dark:text-[#FFDE59]">
                  Loading...
                </span>
                <span className="absolute mt-[20px] lg:text-[16px] font-semibold text-white text-center -translate-x-1/2 -translate-y-1/2 top-[60%] left-1/2 sm:text-3xl dark:text-white lg:visible invisible">
                  Loading...
                </span>
              </div>
              <div
                className="hidden duration-700 ease-in-out"
                data-carousel-item
              >
                <img
                  src="assets/hero.png"
                  className="absolute block w-full h-full lg:h-[600px]"
                  alt="..."
                />
                <div className="opacity-70 bg-black absolute block w-full h-full lg:h-[600px]"></div>

                <span className="absolute lg:text-[34px] text-center font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/4 left-1/2 sm:text-3xl dark:text-white-800">
                  Loading...
                </span>
                <span className="absolute lg:text-[16px] font-semibold text-white -translate-x-1/2 -translate-y-1/2 lg:top-[40%] top-[50%] left-1/2 sm:text-3xl dark:text-[#FFDE59]">
                  Loading...
                </span>
                <span className="absolute mt-[20px] lg:text-[16px] font-semibold text-white text-center -translate-x-1/2 -translate-y-1/2 top-[60%] left-1/2 sm:text-3xl dark:text-white lg:visible invisible">
                  Loading...
                </span>
              </div>
            </>
          )}
        </div>
        <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
          <button
            type="button"
            className="w-3 h-3 rounded-full"
            aria-current="false"
            aria-label="Slide 1"
            data-carousel-slide-to="0"
          ></button>
          <button
            type="button"
            className="w-3 h-3 rounded-full"
            aria-current="false"
            aria-label="Slide 2"
            data-carousel-slide-to="1"
          ></button>
          <button
            type="button"
            className="w-3 h-3 rounded-full"
            aria-current="false"
            aria-label="Slide 3"
            data-carousel-slide-to="2"
          ></button>
        </div>
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-white-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-white-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-white-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-white-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>

      <div ref={refBerita}>
        <Fade left when={isVisibleBerita}>
          <div className="mt-[7%] mx-[11%]">
            <span className="text-[40px] md:text-[60px]">Berita Terkini</span>
            <div className="mt-10 flex flex-col items-center bg-white rounded-lg md:flex-row md:max-w-full dark:bg-white">
              {!loading ? (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-20">
                    <div className="flex flex-col w-[100%] md:w-[100%] h-[200px] md:max-h-[389px] md:min-h-[329px]">
                      {data.posts.nodes[0].featuredImage && (
                        <img
                          alt={data.posts.nodes[0].featuredImage.node.altText}
                          src={data.posts.nodes[0].featuredImage.node.sourceUrl}
                        />
                      )}
                    </div>
                    <div className="flex flex-col justify-between leading-normal">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-[#006225]">
                        {data.posts.nodes[0].title}
                      </h5>
                      <p
                        className="mb-3 font-normal text-gray-700 dark:text-[#292929]"
                        dangerouslySetInnerHTML={{
                          __html: data.posts.nodes[0].excerpt,
                        }}
                      ></p>
                      <p className="mb-3 font-normal text-gray-700 dark:text-[#515151]">
                        {formatDateIndonesia(data.posts.nodes[0].date)}
                      </p>
                      <Link to={"/detail/" + data.posts.nodes[0].id}>
                        <button className="font-bold text-[12px] p-[8px] bg-[#006225] text-white rounded-full">
                          Baca Selengkapnya {">"}
                        </button>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <div class="text-center mx-auto">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!loading ? (
            <div className="flex flex-wrap justify-center mx-[10%] mt-[3%]">
              <div className="w-full md:w-1/3 p-4">
                <Link to={"/detail/" + data.posts.nodes[1].id}>
                  <div className="bg-white rounded-lg overflow-hidden">
                    {!data.posts.nodes[1].featuredImage ? (
                      <img
                        className="w-full h-56 object-cover object-center"
                        src="https://via.placeholder.com/500x300"
                        alt="Card Image"
                      />
                    ) : (
                      <img
                        className="w-full h-56 object-cover object-center"
                        src={data.posts.nodes[1].featuredImage.node.sourceUrl}
                        alt={data.posts.nodes[1].featuredImage.node.altText}
                      />
                    )}
                    <div className="p-4">
                      <h2 className="text-gray-900 font-bold text-m mb-2 dark:text-[#515151]">
                        {formatDateIndonesia(data.posts.nodes[1].date)}
                      </h2>
                      <p className="text-gray-700 text-base dark:text-[#006225] dark:text-[20px] font-bold">
                        {data.posts.nodes[1].title}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-1/3 p-4">
                <Link to={"/detail/" + data.posts.nodes[2].id}>
                  <div className="bg-white rounded-lg overflow-hidden">
                    {!data.posts.nodes[2].featuredImage ? (
                      <img
                        className="w-full h-56 object-cover object-center"
                        src="https://via.placeholder.com/500x300"
                        alt="Card Image"
                      />
                    ) : (
                      <img
                        className="w-full h-56 object-cover object-center"
                        src={data.posts.nodes[2].featuredImage.node.sourceUrl}
                        alt={data.posts.nodes[2].featuredImage.node.altText}
                      />
                    )}
                    <div className="p-4">
                      <h2 className="text-gray-900 font-bold text-m mb-2 dark:text-[#515151]">
                        {formatDateIndonesia(data.posts.nodes[2].date)}
                      </h2>
                      <p className="text-gray-700 text-base dark:text-[#006225] dark:text-[20px] font-bold">
                        {data.posts.nodes[2].title}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-1/3 p-4">
                <Link to={"/detail/" + data.posts.nodes[3].id}>
                  <div className="bg-white rounded-lg overflow-hidden">
                    {!data.posts.nodes[3].featuredImage ? (
                      <img
                        className="w-full h-56 object-cover object-center"
                        src="https://via.placeholder.com/500x300"
                        alt="Card Image"
                      />
                    ) : (
                      <img
                        className="w-full h-56 object-cover object-center"
                        src={data.posts.nodes[3].featuredImage.node.sourceUrl}
                        alt={data.posts.nodes[3].featuredImage.node.altText}
                      />
                    )}
                    <div className="p-4">
                      <h2 className="text-gray-900 font-bold text-m mb-2 dark:text-[#515151]">
                        {formatDateIndonesia(data.posts.nodes[3].date)}
                      </h2>
                      <p className="text-gray-700 text-base dark:text-[#006225] dark:text-[20px] font-bold">
                        {data.posts.nodes[3].title}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <div class="text-center mx-auto">
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}

          <Link to={"/berita"}>
            <button className="mx-[11%] dark:bg-[#006225] float-right bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded">
              Berita Lainnya
            </button>
          </Link>

          <div className="mx-[10%] mt-[100px]">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
              <div className="col-span-1 p-4">
                <p className="text-[40px] md:dark:text-[60px] font-bold">
                  Dokumentasi
                </p>
                <div className="h-full flex items-center justify-center">
                  {/* <img
                className="object-cover rounded-lg shadow-md"
                src="https://via.placeholder.com/500x300"
                alt="Image"
              /> */}
                  {!loading ? (
                    <ReactPhotoCollage {...settingCollage} />
                  ) : (
                    <div class="text-center mx-auto">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mx-[10%] mt-[100px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div className="col-span-1 p-4">
                <p className="text-[40px] md:dark:text-[60px] font-bold">
                  Instagram
                </p>
                <div className="h-full flex items-center justify-center">
                  {/* <img
                className="object-cover rounded-lg shadow-md"
                src="https://via.placeholder.com/500x300"
                alt="Image"
              /> */}
                  {!loading ? (
                    <iframe
                      src="https://www.instagram.com/iatl_itb/embed/"
                      width="320"
                      height="400"
                      frameborder="0"
                      scrolling="no"
                      allowtransparency="true"
                    ></iframe>
                  ) : (
                    <div class="text-center mx-auto">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-1 p-4">
                <p className="text-[40px] md:dark:text-[60px] font-bold">
                  Youtube
                </p>
                <div className="h-full flex items-center justify-center">
                  {/* <img
                className="object-cover rounded-lg shadow-md"
                src="https://via.placeholder.com/500x300"
                alt="Image"
              /> */}
                  <iframe
                    width="500"
                    height="300"
                    src={"//www.youtube.com/embed/" + videoId}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#05355E] mx-auto mt-[100px] pt-[50px] pb-[50px]">
            <p className="mx-[10%] text-[40px] md:text-[64px] text-white text-center font-bold">
              IATL DALAM ANGKA
            </p>

            {true && (
              <div className="md:mx-[179px]">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-4">
                      <div className="flex justify-center items-center">
                        {angka.FotoJumlahAlumni ? (
                          <div
                            className="w-[112px] h-[112px] rounded-full bg-[#C04953]"
                            style={{
                              backgroundSize: "cover",
                              backgroundImage:
                                'url("' + angka.FotoJumlahAlumni + '")',
                            }}
                          ></div>
                        ) : (
                          <div className="w-[112px] h-[112px] rounded-full bg-[#C04953]"></div>
                        )}
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h2
                        id="jumlahAlumni"
                        className="text-[40px] font-bold text-[#FFDE59]"
                      ></h2>
                      <p className="text-[18px] font-bold mb-4 text-[#FFDE59]">
                        Jumlah Alumni
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-4">
                      <div className="flex justify-center items-center">
                        {angka.FotoDatabase ? (
                          <div
                            className="w-[112px] h-[112px] rounded-full bg-[#C04953]"
                            style={{
                              backgroundSize: "cover",
                              backgroundImage:
                                'url("' + angka.FotoDatabase + '")',
                            }}
                          ></div>
                        ) : (
                          <div className="w-[112px] h-[112px] rounded-full bg-[#C04953]"></div>
                        )}
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h2
                        id="database"
                        className="text-[40px] font-bold text-[#FFDE59]"
                      ></h2>
                      <p className="text-[18px] font-bold mb-4 text-[#FFDE59]">
                        Database
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-4">
                      <div className="flex justify-center items-center">
                        {angka.FotoKolabolator ? (
                          <div
                            className="w-[112px] h-[112px] rounded-full bg-[#C04953]"
                            style={{
                              backgroundSize: "cover",
                              backgroundImage:
                                'url("' + angka.FotoKolabolator + '")',
                            }}
                          ></div>
                        ) : (
                          <div className="w-[112px] h-[112px] rounded-full bg-[#C04953]"></div>
                        )}
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h2
                        id="kolabolator"
                        className="text-[40px] font-bold text-[#FFDE59]"
                      ></h2>
                      <p className="text-[18px] font-bold mb-4 text-[#FFDE59]">
                        Kolabolator
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-4">
                      <div className="flex justify-center items-center">
                        {angka.FotoReach ? (
                          <div
                            className="w-[112px] h-[112px] rounded-full bg-[#C04953]"
                            style={{
                              backgroundSize: "cover",
                              backgroundImage: 'url("' + angka.FotoReach + '")',
                            }}
                          ></div>
                        ) : (
                          <div className="w-[112px] h-[112px] rounded-full bg-[#C04953]"></div>
                        )}
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h2
                        id="reach"
                        className="text-[40px] font-bold text-[#FFDE59]"
                      ></h2>
                      <p className="text-[18px] font-bold mb-4 text-[#FFDE59]">
                        Reach
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-4">
                      <div className="flex justify-center items-center">
                        {angka.FotoProgram ? (
                          <div
                            className="w-[112px] h-[112px] rounded-full bg-[#C04953]"
                            style={{
                              backgroundSize: "cover",
                              backgroundImage:
                                'url("' + angka.FotoProgram + '")',
                            }}
                          ></div>
                        ) : (
                          <div className="w-[112px] h-[112px] rounded-full bg-[#C04953]"></div>
                        )}
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h2
                        id="program"
                        className="text-[40px] font-bold text-[#FFDE59]"
                      ></h2>
                      <p className="text-[18px] font-bold mb-4 text-[#FFDE59]">
                        Program
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-4">
                      <div className="flex justify-center items-center">
                        {angka.FotoPartisipanKegiatan ? (
                          <div
                            className="w-[112px] h-[112px] rounded-full bg-[#C04953]"
                            style={{
                              backgroundSize: "cover",
                              backgroundImage:
                                'url("' + angka.FotoPartisipanKegiatan + '")',
                            }}
                          ></div>
                        ) : (
                          <div className="w-[112px] h-[112px] rounded-full bg-[#C04953]"></div>
                        )}
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h2
                        id="partisipanKegiatan"
                        className="text-[40px] font-bold text-[#FFDE59]"
                      ></h2>
                      <p className="text-[18px] font-bold mb-4 text-[#FFDE59]">
                        Partisipan Kegiatan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mx-[11%] py-[120px]">
            <div className="flex flex-col items-center bg-white rounded-lg md:flex-row md:max-w-full dark:bg-white">
              <div className="flex flex-5 flex-col w-[100%] md:w-[70%] h-[200px] md:h-[100%]">
                <img src={"assets/poster.png"} alt="Poster" />
              </div>
              <div className="flex flex-1 flex-col justify-between p-4 leading-normal">
                <h5 className="mb-2 px-[6%] text-3xl font-bold tracking-tight text-gray-900 text-[40px] dark:text-black">
                  <a href="http://tiny.cc/DatabaseIATLITB" target="_blank">
                    <button style={{backgroundColor: '#0155b7', color: 'white', padding: '10px', borderRadius: '90%'}}>Lakukan Pendataan Disini</button>
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <Footer />
    </>
  );
}
