const api = (() => {
    const BASE_URL = 'https://admin.iatl-itb.org/wp-json/api/v1';
  
    async function _fetchWithAuth(url, options = {}) {
      return fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
    }
  
    function putAccessToken(token) {
      localStorage.setItem('accessToken', token);
    }
  
    function getAccessToken() {
      return localStorage.getItem('accessToken');
    }
  
    async function register({ id, name, password }) {
      const response = await fetch(`${BASE_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          name,
          password,
        }),
      });
  
      const responseJson = await response.json();
      const { status, message } = responseJson;
  
      if (status !== 'success') {
        throw new Error(message);
      }
  
      const { data: { user } } = responseJson;
  
      return user;
    }
  
    async function login({ username, password }) {
      const formData  = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch(`${BASE_URL}/token`, {
        method: 'POST',
        body: formData,
      });
  
      const responseJson = await response.json();
  
      const { status, error_description } = responseJson;
  
      if (status === 'error') {
        throw new Error(error_description);
      }
  
      const { jwt_token } = responseJson;
  
      return jwt_token;
    }

    async function validate({ token }) {
      const response = await fetch(`${BASE_URL}/token-validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token
        }),
      });
  
      const responseJson = await response.json();
  
      const { status, message } = responseJson;
  
      if (status !== 'TRUE') {
        throw new Error(message);
      }
  
      return true;
    }
  
    return {
      putAccessToken,
      getAccessToken,
      register,
      login,
      validate,
    };
  })();
  
  export default api;
  