import React from "react";
import { baseURL } from "../utils/config";
import { useNavigate, Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-[#292929] px-[10%] md:px-0 mt-[100px] pt-[50px] pb-[50px]">
      <div className="md:mx-[179px]">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
            <div className="grid grid-cols-3 md:grid-cols-2">
              <div className="p-4">
                <img
                  src={baseURL + "/assets/logo2.png"}
                  className=""
                  alt="logo"
                />
              </div>
              <div className="p-4">
                <img
                  src={baseURL + "/assets/logo-white.png"}
                  className=""
                  alt="logo"
                />
              </div>
            </div>
            <h5 className="text-white w-[300px] text-[14px] font-bold">
              IKATAN ALUMNI TEKNIK LINGKUNGAN INSTITUT TEKNOLOGI BANDUNG
            </h5>
          </div>
          <div></div>
          <div>
            <h5 className="text-[#FFDE59] font-bold text-[16px] mb-[16px]">
              Rekap Kegiatan
            </h5>
            <ul className="font-normal text-[14px] list-dist">
              {/* <li className="text-white">
                <Link to={"/tag/dGVybToyOA=="}>IATalks</Link>
              </li> */}
              <li className="text-white">
                <Link to={"/tag/dGVybTo3NQ=="}>Lingkar Kegiatan</Link>
              </li>
              <li className="text-white">
                <Link to={"/tag/dGVybToxMDE="}>Enviro Webinnar</Link>
              </li>
              <li className="text-white">
                <Link to={"/tag/dGVybToxMDU="}>Newsletter</Link>
              </li>
              <li className="text-white">
                <Link to={"/tag/dGVybTo4Mw=="}>Ulang Tahun & Homecoming</Link>
              </li>
              {/* <li className="text-white">
                <Link to={"/tag/dGVybTo1MQ=="}>CER-IA</Link>
              </li> */}
            </ul>
          </div>
          <div>
            <h5 className="text-[#FFDE59] text-center font-bold text-[16px] mb-[16px]">
              Temukan Kami
            </h5>
            <p className="text-center font-normal text-[14px] text-white mb-5">
              Jl Gambir Anom No 44, Sukaluyu, Cibeunying Kaler, Bandung 40123
              <br />
              <br />
              +62 813-9518-0404
            </p>
            <div className="grid grid-cols-4 md:grid-cols-4 gap-10">
              <div className="flex col-span-1 items-center justify-center w-16 h-16 bg-[#FFDE59] rounded-full">
                <div className="w-10">
                  <a
                    target="_blank"
                    href="https://youtube.com/@iatlitb2988"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="flex col-span-1 items-center justify-center w-16 h-16 bg-[#FFDE59] rounded-full">
                <div className="w-10">
                  <a
                    target="_blank"
                    href="https://instagram.com/iatl_itb"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="flex col-span-1 items-center justify-center w-16 h-16 bg-[#FFDE59] rounded-full">
                <div className="w-10">
                  <a
                    target="_blank"
                    href="mailto:hai.iatl.itb@gmail.com"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="flex col-span-1 items-center justify-center w-16 h-16 bg-[#FFDE59] rounded-full">
                <div className="w-10">
                  <a
                    target="_blank"
                    href="http://wa.me/6281395180404"
                    rel="noreferrer"
                  >
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="whatsapp"><path d="M8.002 0h-.004C3.587 0 0 3.588 0 8a7.94 7.94 0 0 0 1.523 4.689l-.997 2.972 3.075-.983A7.93 7.93 0 0 0 8.002 16C12.413 16 16 12.411 16 8s-3.587-8-7.998-8zm4.655 11.297c-.193.545-.959.997-1.57 1.129-.418.089-.964.16-2.802-.602-2.351-.974-3.865-3.363-3.983-3.518-.113-.155-.95-1.265-.95-2.413s.583-1.707.818-1.947c.193-.197.512-.287.818-.287.099 0 .188.005.268.009.235.01.353.024.508.395.193.465.663 1.613.719 1.731.057.118.114.278.034.433-.075.16-.141.231-.259.367-.118.136-.23.24-.348.386-.108.127-.23.263-.094.498.136.23.606.997 1.298 1.613.893.795 1.617 1.049 1.876 1.157.193.08.423.061.564-.089.179-.193.4-.513.625-.828.16-.226.362-.254.574-.174.216.075 1.359.64 1.594.757.235.118.39.174.447.273.056.099.056.564-.137 1.11z"></path></svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-[50px]">
          <p className="text-[#FAF3EB] font-normal text-[10px]">
            © Powered by IATL ITB 2021/2024
          </p>
        </div>
      </div>
    </div>
  );
}
