import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { CSSTransition } from "react-transition-group";
import { gql, useQuery } from "@apollo/client";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import LoadingComponent from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCheckAuthUser,
  asyncUnsetAuthUser,
} from "../states/authUser/action";

export default function AlumniPage() {
  const navigate = useNavigate();
  const { authUser } = useSelector((states) => states);

  const GET_ONE_LAST_POST = gql`
    query getAllPosts {
      posts(last: 1) {
        nodes {
          databaseId
          title
          date
          content
          slug
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  `;

  const search = useRef(null);
  const profesiFilter = useRef(null);
  const domisiliFilter = useRef(null);
  const jurusanFilter = useRef(null);
  const angkatanFilter = useRef(null);
  const [isLoading, setIsloading] = useState(true);
  const [alumni, setAlumni] = useState([]);
  const [alumniTemp, setAlumniTemp] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(8);
  const [perPage, setPerPage] = useState(9);
  const [profesiList, setProfesiList] = useState([]);
  const [domisiliList, setDomisiliList] = useState([
    "Indonesia",
    "Bukan Indonesia",
  ]);
  const [jurusanList, setJurusanList] = useState([]);
  const [angkatanList, setAngkatanList] = useState([]);

  const dispatch = useDispatch(); // @TODO: get dispatch function from store

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    }

    const options = {
      url: "https://api.fureweb.com/spreadsheets/1Q8wY_ye9o0e-HsQzA3WQWjWAr7mzEe-AXW6j0wPXXHU",
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    };

    axios(options)
      .then((response) => {
        const responseData = response.data.data;
        setIsloading(false);
        setAlumni(responseData);
        setAlumniTemp(responseData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const angkatanTemp = [];
    const jurusanTemp = [];
    const profesiTemp = [];
    alumniTemp.map((item) => {
      if (!angkatanTemp.includes(item?.Angkatan)) {
        if (item?.Angkatan !== undefined) {
          angkatanTemp.push(item?.Angkatan);
        }
      }
    });
    alumniTemp.map((item) => {
      if (!jurusanTemp.includes(item?.Jurusan)) {
        if (item?.Jurusan !== undefined) {
          jurusanTemp.push(item?.Jurusan);
        }
      }
    });
    alumniTemp.map((item) => {
      if (!profesiTemp.includes(item?.Profesi)) {
        if (item?.Profesi !== undefined) {
          profesiTemp.push(item?.Profesi);
        }
      }
    });
    setAngkatanList(angkatanTemp);
    setJurusanList(jurusanTemp);
    setProfesiList(profesiTemp);
  }, [alumniTemp]);

  const onNext = () => {
    setStartIndex(startIndex + perPage);
    setEndIndex(endIndex + perPage);
  };

  const onPrev = () => {
    setStartIndex(startIndex - perPage);
    setEndIndex(endIndex - perPage);
  };

  const onSearch = () => {
    setAlumni(alumniTemp);
    if (
      domisiliFilter.current.value &&
      domisiliFilter.current.value !== null &&
      domisiliFilter.current.value !== "Domisili"
    ) {
      console.log("masuk domisili");
      if (domisiliFilter.current.value.toLowerCase() === "indonesia") {
        setAlumni((alumniBefore) =>
          alumniBefore.filter((item) => {
            return item["Domisili Indonesia"] === "Ya";
          })
        );
      } else {
        setAlumni((alumniBefore) =>
          alumniBefore.filter((item) => {
            return item["Domisili Indonesia"] === "Tidak";
          })
        );
      }
    }
    if (
      angkatanFilter.current.value &&
      angkatanFilter.current.value !== null &&
      angkatanFilter.current.value !== "Angkatan"
    ) {
      console.log("masuk angkatan");
      setAlumni((alumniBefore) =>
        alumniBefore.filter((item) => {
          return (
            parseInt(item.Angkatan) === parseInt(angkatanFilter.current.value)
          );
        })
      );
    }
    if (
      profesiFilter.current.value &&
      profesiFilter.current.value !== null &&
      profesiFilter.current.value !== "Profesi"
    ) {
      console.log("masuk profesi");
      setAlumni((alumniBefore) =>
        alumniBefore.filter((item) => {
          return item.Profesi?.toLowerCase().includes(
            profesiFilter.current.value.toLowerCase()
          );
        })
      );
    }
    if (
      jurusanFilter.current.value &&
      jurusanFilter.current.value !== null &&
      jurusanFilter.current.value !== "Jurusan"
    ) {
      console.log("masuk jurusan");
      setAlumni((alumniBefore) =>
        alumniBefore.filter((item) => {
          return item.Jurusan?.toLowerCase().includes(
            jurusanFilter.current.value.toLowerCase()
          );
        })
      );
    }
    if (search.current.value || search.current.value !== "") {
      setAlumni((alumniBefore) =>
        alumniBefore.filter((item) =>
          item.Nama?.toLowerCase().includes(search.current.value.toLowerCase())
        )
      );
    }
  };

  // console.log(alumni);

  const { loading, error, data } = useQuery(GET_ONE_LAST_POST);

  const [showCategory, setShowCategory] = useState(false);

  const handleButtonClick = () => {
    setShowCategory(!showCategory);
  };

  return (
    <>
      <Navbar />

      <div className="mx-[10%] md:mb-[20px] mt-[20px]">
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="order-2 md:order-1 col-span-2 md:p-[30px] md:flex md:flex-row">
            <div
              onClick={handleButtonClick}
              className="bg-[#D9D9D9] w-[121px] p-[10px] rounded-full text-center m-[10px]"
            >
              Filter:
            </div>
            <CSSTransition
              in={showCategory}
              timeout={500}
              classNames="slide-down"
              unmountOnExit
            >
              <div class="grid grid-cols-1 md:grid-cols-4">
                <div class="p-[10px]">
                  <select
                    ref={profesiFilter}
                    onChange={onSearch}
                    id="countries"
                    class="text-[0.8rem] bg-[#D9D9D9] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option selected disabled>
                      Profesi
                    </option>
                    {profesiList.map((profesi) => {
                      return <option value={profesi}>{profesi}</option>;
                    })}
                  </select>
                </div>
                <div class="p-[10px]">
                  <select
                    ref={domisiliFilter}
                    onChange={onSearch}
                    id="countries"
                    class="text-[0.8rem] bg-[#D9D9D9] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option selected disabled>
                      Domisili
                    </option>
                    {domisiliList.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div class="p-[10px]">
                  <select
                    ref={jurusanFilter}
                    onChange={onSearch}
                    id="countries"
                    class="text-[0.8rem] bg-[#D9D9D9] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option selected disabled>
                      Jurusan
                    </option>
                    {jurusanList.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div class="p-[10px]">
                  <select
                    ref={angkatanFilter}
                    onChange={onSearch}
                    id="countries"
                    class="text-[0.8rem] bg-[#D9D9D9] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option selected disabled>
                      Angkatan
                    </option>
                    {angkatanList.map((angkatan) => {
                      return <option value={angkatan}>{angkatan}</option>;
                    })}
                  </select>
                </div>
              </div>
            </CSSTransition>
          </div>
          <div className="order-1 md:order-2 col-span-1 md:p-[30px]">
            <div className="flex flex-row">
              <input
                className="m-[10px] w-[100%]"
                type="text"
                placeholder="Tulis Disini"
                name=""
                id=""
                ref={search}
              />
              <button
                onClick={(e) => onSearch()}
                className="m-[10px] bg-[#D9D9D9] p-[10px] rounded-full"
              >
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-[10%] md:mb-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5 gap-y-12 gap-x-[200px]">
          {!isLoading ? (
            alumni.length > 0 ? (
              alumni.map((item, index) => {
                if (index >= startIndex && index <= endIndex) {
                  return (
                    <div className="col-span-1">
                      {item.Foto ? (
                        <div
                          className="w-[250px] h-[250px] bg-[#FFDE59] "
                          style={{
                            backgroundSize: "cover",
                            backgroundImage:
                              'url("'+item.Foto+'")',
                          }}
                        ></div>
                      ) : (
                        <div className="w-[250px] h-[250px] bg-[#FFDE59] "></div>
                      )}
                      <div className="text-center text-[#006225] text-[20px] font-bold mt-[30px] mb-[15px]">
                        {item.Nama}
                      </div>
                      <div className="text-center text-[#292929] text-[14px] mb-[15px]">
                        NIM : {item.NIM ? item.NIM : "Tidak diketahui"} <br />
                        Angkatan :{" "}
                        {item.Angkatan ? item.Angkatan : "Tidak diketahui"}{" "}
                        <br />
                        Kontak : {item.Email
                          ? item.Email
                          : "Tidak diketahui"}{" "}
                        <br />
                        Profesi :{" "}
                        {item.Profesi ? item.Profesi : "Tidak diketahui"} <br />
                        Domisili Indonesia :{" "}
                        {item["Domisili Indonesia"]
                          ? item["Domisili Indonesia"]
                          : "Tidak diketahui"}{" "}
                        <br />
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="col-span-3 text-center">Data tidak ditemukan</div>
            )
          ) : (
            <div className="md:col-span-3 col-span-1">
              <LoadingComponent />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center space-x-4 mt-[50px]">
        {startIndex !== 0 && (
          <button
            onClick={(e) => onPrev()}
            className="px-3 py-1 bg-[#D9D9D9] rounded-md"
          >
            {"<"}
          </button>
        )}
        {/* <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          1
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          2
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          3
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          ...
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          7
        </a> */}
        {endIndex < alumni.length && (
          <button
            onClick={(e) => onNext()}
            className="px-3 py-1 bg-[#D9D9D9] rounded-md"
          >
            {">"}
          </button>
        )}
      </div>

      <Footer />
    </>
  );
}
