import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import LoadingComponent from "../components/Loading";
import formatDateIndonesia from "../utils/index";
import useIsVisible from "../utils/checkVisible";
import { useNavigate, Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import axios from "axios";

export default function ProjectDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [project, setProject] = useState([]);
  const [projectTemp, setProjectTemp] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    // if (!token) {
    //   navigate("/login");
    // }

    const options = {
      url: "https://api.fureweb.com/spreadsheets/1n2Z9pgs5eUp4TJR5LP92Lm30vXhNTGcWWRm5LrXBBDk",
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    };

    axios(options)
      .then((response) => {
        const responseData = response.data.data;
        setProject(responseData);
        setProjectTemp(responseData);
        setIsloading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //   const GET_DETAIL_POST = gql`
  //     query getAllPosts($id: ID!) {
  //       post(id: $id) {
  //         databaseId
  //         excerpt
  //         title
  //         id
  //         date
  //         content
  //         slug
  //         categories {
  //           edges {
  //             node {
  //               id
  //               name
  //             }
  //           }
  //         }
  //         author {
  //           node {
  //             name
  //           }
  //         }
  //         featuredImage {
  //           node {
  //             altText
  //             sourceUrl
  //           }
  //         }
  //       }
  //     }
  //   `;

  //   const { loading, error, data } = useQuery(GET_DETAIL_POST, {
  //     variables: { id: id },
  //   });

  const [refBerita, isVisibleBerita] = useIsVisible();

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  return (
    <>
      <Navbar />

      <div ref={refBerita}>
        <Fade left when={isVisibleBerita}>
          <div className="mt-[50px] mx-[11%]">
            {!isLoading ? (
              <>
                <div className="mt-[50px] container flex flex-col items-center bg-white rounded-lg md:max-w-full dark:bg-white">
                  <div
                    className="sm:flex-col md:flex-row flex-col"
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      className="image-container sm:w-[100%] md:w-[50%] w-[100%]"
                      style={{
                        flex: 1,
                      }}
                    >
                      <img
                        src="https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w="
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                        alt="Image"
                        className="image"
                      />
                    </div>
                    <div
                      className="column sm:w-[100%] md:w-[50%] w-[100%]"
                      style={{
                        flex: 1,
                        paddingLeft: "30px",
                        paddingRigt: "30px",
                      }}
                    >
                      <h3 className="text-[30px]">
                        {project[id]["Nama Project"]}
                      </h3>
                      <div
                        className="mb-5"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <span>Kategori: {project[id]["Kategori"]}</span>
                        <span>{project[id]["Tempat"]}</span>
                        <span>{project[id]["Penyelenggara"]}</span>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Terkumpul</span>
                        <span>Dana Dibutuhkan</span>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {  project[id].hasOwnProperty("Terkumpul") ? formatter.format(project[id]["Terkumpul"]) : formatter.format(0)}
                        </span>
                        <span>
                          {formatter.format(project[id]["Dana Dibutuhkan"])}
                        </span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                        <div
                          className="bg-green-600 text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
                          style={{
                            width: `${
                              project[id].hasOwnProperty("Terkumpul")
                              ? 
                              (project[id]["Terkumpul"] /
                                project[id]["Dana Dibutuhkan"]) *
                              100 : 0
                            }%`,
                          }}
                        >
                          {" "}
                          {  project[id].hasOwnProperty("Terkumpul") ? (project[id]["Terkumpul"] /
                            project[id]["Dana Dibutuhkan"]) *
                            100 : 0}
                          %
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Terkumpul</span>
                        <span>Durasi</span>
                      </div>
                      <div
                        className="mb-5"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {project[id]["Status"] === "Open" ? (
                          <span className="text-green-600">Open</span>
                        ) : (
                          <span className="text-red-600">Close</span>
                        )}
                        <span>{project[id]["Durasi"]}</span>
                      </div>
                      <div>
                        <a target="_blank" href="https://wa.me/6282262311986">
                          <button
                            type="button"
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
                          >
                            Donasi Sekarang
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <LoadingComponent />
            )}
          </div>
          <div className="mt-[50px] mx-[11%]">
            <div className="mt-[50px] container flex flex-col items-center bg-white rounded-lg md:max-w-full dark:bg-white">
              <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul
                  className="flex flex-wrap -mb-px text-sm font-medium text-center"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li className="mr-2" role="presentation">
                    <button
                      className="inline-block p-4 border-b-2 rounded-t-lg"
                      id="profile-tab"
                      data-tabs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Detail
                    </button>
                  </li>
                  <li className="mr-2" role="presentation">
                    <button
                      className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                      id="dashboard-tab"
                      data-tabs-target="#dashboard"
                      type="button"
                      role="tab"
                      aria-controls="dashboard"
                      aria-selected="false"
                    >
                      Update
                    </button>
                  </li>
                  <li className="mr-2" role="presentation">
                    <button
                      className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                      id="settings-tab"
                      data-tabs-target="#settings"
                      type="button"
                      role="tab"
                      aria-controls="settings"
                      aria-selected="false"
                    >
                      Donatur
                    </button>
                  </li>
                </ul>
              </div>
              <div id="myTabContent">
                <div
                  className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {!isLoading && (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {project[id]["Detail"]}
                    </p>
                  )}
                </div>
                <div
                  className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                  id="dashboard"
                  role="tabpanel"
                  aria-labelledby="dashboard-tab"
                >
                 {!isLoading && (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {project[id]["Update"]}
                    </p>
                  )}
                </div>
                <div
                  className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                  id="settings"
                  role="tabpanel"
                  aria-labelledby="settings-tab"
                >
                  {!isLoading && (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {project[id]["Donatur"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <Footer />
    </>
  );
}
