import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { CSSTransition } from "react-transition-group";
import { gql, useQuery } from "@apollo/client";
import LoadingComponent from "../components/Loading";
import formatDateIndonesia from "../utils/index";
import { baseURL } from "../utils/config";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

export default function ProjectPage() {
  const navigate = useNavigate();
  const search = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSingle, setSelectedSingle] = useState("");

  const [startIndex, setStartIndex] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [endIndex, setEndIndex] = useState(8);
  const [perPage, setPerPage] = useState(9);

  const [kategoriList, setKategoriList] = useState([]);
  const kategoriFilter = useRef(null);

  const [project, setProject] = useState([]);
  const [projectTemp, setProjectTemp] = useState([]);

  const [showCategory, setShowCategory] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    // if (!token) {
    //   navigate("/login");
    // }

    const options = {
      url: "https://api.fureweb.com/spreadsheets/1n2Z9pgs5eUp4TJR5LP92Lm30vXhNTGcWWRm5LrXBBDk",
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    };

    axios(options)
      .then((response) => {
        const responseData = response.data.data;
        setIsloading(false);
        setProject(responseData);
        setProjectTemp(responseData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const kategoriTemp = [];
    projectTemp.map((item) => {
      if (!projectTemp.includes(item?.Kategori)) {
        if (item?.Kategori !== undefined) {
          kategoriTemp.push(item?.Kategori);
        }
      }
    });
    setKategoriList(kategoriTemp);
  }, [projectTemp]);

  const handleButtonClick = () => {
    setShowCategory(!showCategory);
  };

  const onSearch = () => {
    setProject(projectTemp);
    if (
      kategoriFilter.current?.value &&
      kategoriFilter.current?.value !== null &&
      kategoriFilter.current?.value !== "Kategori"
    ) {
      console.log("masuk kategori");
      setProject((projectBefore) =>
        projectBefore.filter((item) => {
          return item.Kategori
            ?.toLowerCase()
            .includes(kategoriFilter.current.value.toLowerCase());
        })
      );
    }

    if (search.current.value || search.current.value !== "") {
      setProject((projectBefore) =>
        projectBefore.filter((item) =>
          item["Nama Project"]?.toLowerCase().includes(search.current.value.toLowerCase())
        )
      );
    }
  };

  const onNext = () => {
    setStartIndex(startIndex + perPage);
    setEndIndex(endIndex + perPage);
  };

  const onPrev = () => {
    setStartIndex(startIndex - perPage);
    setEndIndex(endIndex - perPage);
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  return (
    <>
      <Navbar />

      <div className="mx-[10%] md:mb-[20px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5">
          <div className="order-2 md:order-1 col-span-2 md:p-[30px] md:flex md:flex-row">
            <div
              onClick={handleButtonClick}
              className="bg-[#D9D9D9] w-[121px] p-[10px] rounded-full text-center m-[10px]"
            >
              Filter:
            </div>
            <CSSTransition
              in={showCategory}
              timeout={500}
              classNames="slide-down"
              unmountOnExit
            >
              <>
                <div class="grid grid-cols-1 md:grid-cols-4">
                  <div class="p-[10px]">
                    <select
                      ref={kategoriFilter}
                      onChange={onSearch}
                      id="countries"
                      class="text-[0.8rem] bg-[#D9D9D9] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    >
                      <option selected disabled>
                        Kategori
                      </option>
                      {kategoriList.map((kategori) => {
                        return <option value={kategori}>{kategori}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </>
            </CSSTransition>
          </div>
          <div className="order-1 md:order-2 col-span-1 md:mt-[30px]">
            <div className="flex flex-row">
              <input
                className="m-[10px] w-[100%]"
                type="text"
                placeholder="Tulis Disini"
                name=""
                id=""
                ref={search}
              />
              <button
                onClick={(e) => onSearch(e)}
                className="m-[10px] bg-[#D9D9D9] p-[10px] rounded-full"
              >
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-[10%] md:mb-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 m-5 gap-[100px]">
          {!isLoading ? (
            project.length > 0 ? (
              project.map((item, index) => {
                if (index >= startIndex && index <= endIndex) {
                  return (
                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-white dark:border-gray-800">
                      <div className="card-image">
                        <img
                          src={item["Gambar Project"]} // Replace with your image URL
                          alt="Card Image"
                        />
                      </div>
                      <div class="p-5">
                        <span class="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                          {item.Kategori}
                        </span>

                        <a href="#">
                          <h5 class="mb-2 text-2xl font-bold tracking-tight dark:text-gray-900 ">
                            {item["Nama Project"]}
                          </h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                          {item.Tempat}
                        </p>
                        <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                          <div
                            class="bg-green-600 text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
                            style={{ width: `${ item.hasOwnProperty("Terkumpul") ? (item["Terkumpul"]/item["Dana Dibutuhkan"] * 100) : 0 }%` }}
                          >
                            {" "}
                            {item.hasOwnProperty("Terkumpul") ? (item["Terkumpul"]/item["Dana Dibutuhkan"] * 100).toFixed(2) : 0}%
                          </div>
                        </div>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Terkumpul</span>
                          <span>Durasi</span>
                        </div>
                        <div
                          className="mb-5"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="text-green-600">{ item.hasOwnProperty("Terkumpul") ? formatter.format(item['Terkumpul']) : formatter.format(0) }</span>
                          <span>{item.Durasi}</span>
                        </div>
                        <div className="mb-3">
                          <a
                            target="_blank"
                            href="https://wa.me/6282262311986"
                            class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                          >
                            Ikut Donasi{" "}
                          </a>
                        </div>
                        <div>
                          <a
                            href={baseURL + "/detailproject/" + index}
                            class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                          >
                            Informasi Project{" "}
                            <svg
                              aria-hidden="true"
                              class="w-4 h-4 ml-2 -mr-1"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="col-span-3 text-center">Data tidak ditemukan</div>
            )
          ) : (
            <div className="md:col-span-3 col-span-1">
              <LoadingComponent />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center space-x-4 mt-[50px]">
        {startIndex !== 0 && (
          <button
            onClick={(e) => onPrev()}
            className="px-3 py-1 bg-[#D9D9D9] rounded-md"
          >
            {"<"}
          </button>
        )}
        {/* <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          1
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          2
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          3
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          ...
        </a>
        <a href="#" className="px-3 py-1 bg-[#D9D9D9] rounded-md">
          7
        </a> */}
        {endIndex < project.length && (
          <button
            onClick={(e) => onNext()}
            className="px-3 py-1 bg-[#D9D9D9] rounded-md"
          >
            {">"}
          </button>
        )}
      </div>

      <Footer />
    </>
  );
}
